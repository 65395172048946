import jQuery from "jquery";

( function( $ ) {

	var site = {

		bootstrap: function() {

			site.initVideoPlayer();

		},

		initVideoPlayer: function() {

			$(".play-video").on( 'click', function() {

				// Player einblenden
				$("body").addClass("video-playing");

				var brand = $(this).attr("data-video");

				// Video für Marke einblenden
				var $video = $(".video--" + brand);
				console.log($video);
				$video.removeClass("hidden");

				// Video starten
				$video.get(0).play();

			} );

			$(".close-video").on( 'click', function() {

				// Player ausblenden
				$("body").removeClass("video-playing");

				// Video ausblenden
				var $video = $("video").not(".hidden");
				$video.addClass("hidden");

				// Video-Player stoppen
				$video.get(0).pause();
				$video.get(0).currentTime = 0;


			})

		}

	};

	$(document).ready( site.bootstrap );

} )( jQuery );